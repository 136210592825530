<template>
    <div class="modal is-active" :class="{ 'pointer-events-none': isLoading }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <!-- overlay loading screen -->
            <div v-if="isLoading" class="is-loading">
                <div class="spinner"></div>
            </div>
            <!-- header -->
            <header class="modal-card-head">
                <p class="modal-card-title">{{ title }}</p>
                <button
                    :class="{ 'pointer-events-none': isLoading }"
                    class="delete"
                    aria-label="close"
                    v-on:click="$parent.toggleModal()"
                ></button>
            </header>
            <section class="modal-card-body">
                <!-- search bar -->
                <div
                    class="columns is-variable is-1 is-centered is-mobile"
                    v-if="!isEdit"
                >
                    <div class="column is-narrow">
                        <a v-on:click=" prevMovie()" :class="prevMovie(true) ? '' : 'is-disabled'">
                            <font-awesome-icon
                                class="arrow-icon"
                                :icon="['fas', 'caret-left']"
                            />
                        </a>
                    </div>
                    <div class="column is-narrow field has-addons">
                        <div class="control">
                            <input
                                class="input"
                                type="text"
                                placeholder="Suche"
                                v-model="searchTerm"
                            >
                        </div>
                        <div class="control">
                            <a class="button is-info" v-on:click="getImdbMovies()">
                                <font-awesome-icon
                                    :icon="['fas', 'magnifying-glass']"
                                />
                            </a>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <a v-on:click=" nextMovie()" :class="nextMovie(true) ? '' : 'is-disabled'">
                            <font-awesome-icon
                                class="arrow-icon"
                                :icon="['fas', 'caret-right']"
                            />
                        </a>
                    </div>
                </div>
                <!-- main window -->
                <message-box
                    v-if="messageBox.isVisible"
                    :message="messageBox.message"
                />
                <div class="columns">
                    <!-- cover -->
                    <div class="column is-one-fifth cover-container">
                        <div id="img" class="cover">
                            <img
                                class="is-full-width cover-image"
                                :src="movie.cover || movie.cover_url || require('@/assets/default_cover.jpg')"
                                alt="Cover"
                            >
                            <button class="button edit-icon" v-on:click="toggleFileUploadFields()">
                                <font-awesome-icon
                                    class=""
                                    :icon="['far', 'pen-to-square']"
                                />
                            </button>
                        </div>
                        <div class="field cover-fields slide-down">
                            <!-- file upload -->
                            <!-- <div>
                                <div>
                                    <p>Upload a file</p>
                                    <button @click="showFileSelect = !showFileSelect">Select a file</button>
                                </div>
                                <div v-show="showFileSelect">
                                    <FileUpload :maxSize="1" accept="png" @file-uploaded="getUploadedData" />
                                </div>

                                <div v-if="fileSelected">
                                    Successfully Selected file: {{ file.name }}.{{ file.fileExtention }}
                                </div>
                            </div> -->
                            <!-- file upload -->

                            <div>
                                <input-field
                                    :value="movie.cover_url"
                                    inputType="text"
                                    placeholder="Cover URL"
                                    @get-value="(value) => movie.cover_url = value"
                                ></input-field>
                            </div>
                            <div class="file mt-2">
                                <label class="file-label">
                                    <input id="fileUpload" class="file-input" type="file" name="" v-on:change="selectCover($event)">
                                    <span class="file-cta">
                                    <span class="file-icon">
                                        <font-awesome-icon
                                            class=""
                                            :icon="['fas', 'upload']"
                                        />
                                    </span>
                                    <span class="file-label">
                                        Datei wählen
                                    </span>
                                    </span>
                                </label>
                            </div>
                        </div> 
                    </div>
                    <!-- attributes -->
                    <div class="column">
                        <!-- title -->
                        <div class="columns">
                            <div class="column">
                                <input-field
                                    :value="movie.name"
                                    inputType="text"
                                    placeholder="Titel des Films"
                                    @get-value="(value) => movie.name = value"
                                ></input-field>
                                
                            </div>
                            <!-- movie / series -->
                            <div class="buttons has-addons column is-narrow">
                                <button
                                    class="button"
                                    :class="getButtonState(false, 'is_series')"
                                    v-on:click="toggleField('is_series')"
                                >
                                    Film
                                </button>
                                <button
                                    class="button"
                                    :class="getButtonState(true, 'is_series')"
                                    v-on:click="toggleField('is_series')"
                                >
                                    Serie
                                </button>
                            </div>
                        </div>
                        <!-- year, country, etc. -->
                        <div class="columns">
                            <div class="column">
                                <input-field
                                    inputType="dropdown"
                                    :placeholder="movie.release_year || 'Jahr'"
                                    :options="years"
                                    @get-value="(value) => movie.release_year = value"
                                ></input-field>
                            </div>
                            <div class="column">
                                <input-field
                                    inputType="dropdown"
                                    :placeholder="country.name || 'Land'"
                                    :options="getObjNamesFromArray(countries)"
                                    @get-value="(value) => country.name = value"
                                ></input-field>
                            </div>  
                            <div class="column">
                                <input-field
                                    :value="movie.running_time"
                                    inputType="text"
                                    placeholder="Minuten"
                                    @get-value="(value) => movie.running_time = value"
                                ></input-field>
                            </div>  
                            <div class="column is-one-quarter">
                                <div class="columns is-variable is-1 is-mobile">
                                    <div class="column is-narrow">
                                        <a v-on:click=" prevDirector()" :class="prevDirector(true) ? '' : 'is-disabled'">
                                            <font-awesome-icon
                                                class="arrow-icon"
                                                v-if="!isEdit"
                                                :icon="['fas', 'caret-left']"
                                            />
                                        </a>
                                    </div>
                                    <div class="column">
                                        <input-field
                                            :value="directorName"
                                            inputType="text"
                                            placeholder="Regisseur"
                                            :options="getObjNamesFromArray(directors)"
                                            @get-value="(value) => directorName = value"
                                        ></input-field>
                                    </div>
                                    <div class="column is-narrow">
                                        <a v-on:click=" nextDirector()" :class="nextDirector(true) ? '' : 'is-disabled'">
                                            <font-awesome-icon
                                                class="arrow-icon"
                                                v-if="!isEdit"
                                                :icon="['fas', 'caret-right']"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>  
                            <div class="column">
                                <input-field
                                    inputType="dropdown"
                                    :placeholder="movie.imdb || 'IMDb'"
                                    :options="imdbPoints"
                                    @get-value="(value) => movie.imdb = value"
                                ></input-field>
                            </div>  
                        </div>
                        <!-- genres -->
                        <div class="columns">
                            <div class="column is-one-third field has-addons mb-0">
                                <div class="control is-full-width">
                                    <input-field
                                        inputType="dropdown"
                                        :placeholder="genrePlaceholder"
                                        :options="getObjNamesFromArray(genres)"
                                        @get-value="(value) => selectedGenre = getObjectFromArrayByName(value, genres)"
                                    ></input-field>
                                </div>
                                <div
                                    class="control"
                                    v-on:click="selectItem(selectedGenre, 'Genre(s)', movie.genres)"
                                >
                                    <a class="button is-info">
                                        <font-awesome-icon
                                            :icon="['fas', 'plus']"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div
                                class="column is-narrow"
                                v-for="genre in movie.genres"
                                :key="genre"
                            >
                                <div class="tags has-addons">
                                    <span class="tag">{{ genre.name }}</span>
                                    <a
                                        class="tag is-delete is-danger"
                                        v-on:click="unselectItem(genre, movie.genres)"
                                    ></a>
                                </div>
                            </div>
                        </div>
                        <!-- tags -->
                        <div class="columns">
                            <div class="column is-one-third field has-addons">
                                <div class="control is-full-width">
                                    <input-field
                                        inputType="text"
                                        :placeholder=tagPlaceholder
                                        :options="getObjNamesFromArray(tags)"
                                        @get-value="(value) => selectedTagName = value"
                                    ></input-field>
                                </div>
                                <div
                                    class="control"
                                    v-on:click="checkForNewTag(selectedTagName)"
                                >
                                    <a class="button is-info">
                                        <font-awesome-icon
                                            :icon="['fas', 'plus']"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div
                                class="column is-narrow"
                                v-for="tag in movie.tags"
                                :key="tag"
                            >
                                <div class="tags has-addons">
                                    <span class="tag">{{ tag.name }}</span>
                                    <a
                                        class="tag is-delete is-danger"
                                        v-on:click="unselectItem(tag, movie.tags)"
                                    ></a>
                                </div>
                            </div>
                        </div>
                        <!-- checkboxes -->
                        <div class="columns">
                            <!-- jate -->
                            <div class="column">
                                <label class="label">Jate</label>
                                <div class="columns is-variable is-1 is-mobile">
                                    <!-- rating -->
                                    <div class="column is-narrow">
                                        <div class="buttons has-addons">
                                            <button 
                                                class="button"
                                                v-for="emoji in emojis"
                                                :key="emoji.id"
                                                :class="getButtonState(emoji, 'rating_by_jate')"
                                                v-on:click="setRating(emoji, 'rating_by_jate')"
                                            >
                                                <font-awesome-icon
                                                    class="font-awe-icon emoji-icon"
                                                    :icon="['far', emoji.icon_code]"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <!-- seen -->
                                    <div class="column is-narrow">
                                        <div class="buttons has-addons">
                                            <button
                                                class="button"
                                                :class="getButtonState(true, 'seen_by_jate')"
                                                v-on:click="toggleField('seen_by_jate')"
                                            >
                                                <font-awesome-icon
                                                    class="font-awe-icon emoji-icon"
                                                    :icon="['far', 'eye']"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <!-- currently watching -->
                                    <div class="column is-narrow">
                                        <div class="buttons has-addons">
                                            <button
                                                class="button"
                                                :class="getButtonState(true, 'currently_watching_jate')"
                                                v-on:click="toggleField('currently_watching_jate')"
                                            >
                                                <font-awesome-icon
                                                    class="font-awe-icon emoji-icon"
                                                    :icon="['fas', 'bookmark']"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- flo -->
                            <div class="column">
                                <label class="label">Flo</label>
                                <div class="columns is-variable is-1 is-mobile">
                                    <!-- rating -->
                                    <div class="column is-narrow">
                                        <div class="buttons has-addons">
                                            <button 
                                                class="button"
                                                v-for="emoji in emojis"
                                                :key="emoji.id"
                                                :class="getButtonState(emoji, 'rating_by_flo')"
                                                v-on:click="setRating(emoji, 'rating_by_flo')"
                                            >
                                                <font-awesome-icon
                                                    class="font-awe-icon emoji-icon"
                                                    :icon="['far', emoji.icon_code]"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <!-- seen -->
                                    <div class="column is-narrow">
                                        <div class="buttons has-addons">
                                            <button
                                                class="button"
                                                :class="getButtonState(true, 'seen_by_flo')"
                                                v-on:click="toggleField('seen_by_flo')"
                                            >
                                                <font-awesome-icon
                                                    class="font-awe-icon emoji-icon"
                                                    :icon="['far', 'eye']"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <!-- currently watching -->
                                    <div class="column is-narrow">
                                        <div class="buttons has-addons">
                                            <button
                                                class="button"
                                                :class="getButtonState(true, 'currently_watching_flo')"
                                                v-on:click="toggleField('currently_watching_flo')"
                                            >
                                                <font-awesome-icon
                                                    class="font-awe-icon emoji-icon"
                                                    :icon="['fas', 'bookmark']"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button
                    class="button is-success"
                    v-if="!messageBox.isConfirmDelete"
                    v-on:click="saveAll()"
                >
                    Speichern
                </button>
                <button
                    class="button is-danger"
                    v-if="isEdit && !messageBox.isConfirmDelete"
                    v-on:click="confirmDeletion()"
                >
                    Löschen
                </button>
                <button
                    class="button"
                    v-if="!messageBox.isConfirmDelete"
                    v-on:click="$parent.toggleModal()"
                >
                    Abbrechen
                </button>
                <button
                    class="button is-danger"
                    v-if="messageBox.isConfirmDelete"
                    v-on:click="deleteMovie()"
                >
                    Ja, löschen
                </button>
                <button
                    class="button is-success"
                    v-if="messageBox.isConfirmDelete"
                    v-on:click="abortDeletion()"
                >
                    Nein
                </button>
                <button
                    class="button"
                    v-if="messageBox.isConfirmDelete"
                    v-on:click="abortDeletion()"
                >
                    Abbrechen
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import axios from "@/main.js";
import InputField from "@/components/InputField";
import MessageBox from "@/components/MessageBox";
import FileUpload from "@/components/FileUpload.vue";

    export default {
        name: 'ModalMovie',
        data() {
            return {
                // file upload
                file: {},
                fileSelected: false,
                showFileSelect: false,
                // file upload

                movie: {
                    name: '',
                    release_year: null,
                    genres: [],
                    tags: [],
                    director: null,
                    running_time: null,
                    country: null,
                    is_series: false,
                    seen_by_jate: false,
                    seen_by_flo: false,
                    imdb: null,
                    rating_by_jate: null,
                    rating_by_flo: null,
                    currently_watching_jate: false,
                    currently_watching_flo: false,
                    cover_url: ''
                },
                directorName: '',
                country: { name: '' },
                selectedGenre: '',
                selectedTag: null,
                selectedTagName: '',
                searchTerm: '',
                imdbMovies: [],
                movieIndex: 0,
                directorIndex: 0,
                isLoading: false,
                cover: '',
                messageBox: {
                    isVisible: false,
                    ConfirmDelete: false,
                    message: ''
                },
                tagPlaceholder: 'Tag(s)',
                genrePlaceholder: 'Genre(s)',
                booleanFields: [
                    'is_series',
                    'seen_by_jate',
                    'seen_by_flo',
                    'currently_watching_jate',
                    'currently_watching_flo'
                ]
            };
        },
        props: {
            isEdit: Boolean,
            movieToEdit: {
                type: Object
            }
        },
        components: {
            InputField,
            MessageBox,
            FileUpload
        },
        computed: {
            title() {
                return this.isEdit ? 'Film bearbeiten' : 'Film hinzufügen'
            },
            years() {
                const firstYear = 1900
                const currentYear = new Date().getFullYear()
                const years = []
                for (var i=currentYear; i >= firstYear; i--) {
                    years.push(i)
                }
                return years
            },
            imdbPoints() {
                const points = []
                for (var i=10; i > 0; i-=0.1) {
                    points.push(i.toFixed(1))
                }
                return points
            },
            directors: {
                get () {
                    return this.$store.state.directors
                },
                set (value) {
                    this.$store.commit('setDirectors', value)
                }
            },
            countries: {
                get () {
                    return this.$store.state.countries
                }
            },
            genres: {
                get () {
                    return this.$store.state.genres
                }
            },
            tags: {
                get () {
                    return this.$store.state.tags
                }
            },
            emojis: {
                get () {
                    return this.$store.state.emojis
                }
            }
        },
        mounted() {
            this.setupMovieToEdit()
        },
        methods: {
            // file upload
            getUploadedData(file) {
                this.fileSelected = true;
                this.showFileSelect = false;
                // this.file = file;
                this.movie.cover = file;
            },
            selectCover(e) {
                this.file = e.target.files[0]
                let reader = new FileReader();
                reader.addEventListener("load", () => { this.movie.cover = reader.result }, false);
                reader.readAsDataURL(this.file);
            },
            setupMovieToEdit() {
                if (this.isEdit) {
                    this.movie = this.castProps(this.movieToEdit)
                    if (this.movie.director) this.directorName = this.movie.director.name
                    if (this.movie.genres === '') this.movie.genres = []
                    if (this.movie.tags === '') this.movie.tags = []
                }
            },
            getObjNamesFromArray(objects) {
                const names = []
                objects.forEach(element => names.push(element.name))
                return names
            },
            checkForNewTag(name) {
                if (this.isExistend('tag', name)) {
                    this.selectedTag = this.getObjectFromArrayByName(name, this.tags)
                    this.selectItem(this.selectedTag, this.tagPlaceholder, this.movie.tags)
                } else {
                    this.saveNewTag(name)
                }
            },
            saveNewTag(name) {
                axios.post("/tags/", { 'name': name }).then((response) => {
                    this.$parent.getTags();
                    this.selectItem(response.data, this.tagPlaceholder, this.movie.tags)
                }).catch((error) => {console.log(error)});
            },
            selectItem(item, placeholder, itemArray) {
                if (!itemArray.includes(item) && item !== placeholder && itemArray.length < 3 && item !== '') {
                    itemArray.push(item)
                }
            },
            unselectItem(item, itemArray) {
                const index = itemArray.indexOf(item);
                itemArray.splice(index, 1);
            },
            setRating(rating, user) {
                if (this.movie[user] === rating) {
                    this.movie[user] = null;
                } else {
                    this.movie[user] = this.getObjectFromArrayByName(rating['name'], this.emojis);
                }
            },
            toggleField(field) {
                if (this.movie[field]) {
                    this.movie[field] = false;
                } else {
                    this.movie[field] = true;
                }
            },
            getButtonState(value, field) {
                if (JSON.stringify(value) === JSON.stringify(this.movie[field])) {
                    return 'is-selected is-success'
                }
            },
            isExistend(entity, name) {
                let arrayName = entity + 's'
                if (this.getObjNamesFromArray(this[arrayName]).includes(name)) {
                    return true
                } else {
                    return false
                }
            },
            saveMovie() {
                if (this.movie.running_time === "") this.movie.running_time = null
                if (this.movie.imdb === "") delete this.movie.imdb
                if (this.isEdit) {
                    delete this.movie.cover
                    axios.put("/movies/" + this.movie.id + '/', this.movie).then((response) => {
                        if (this.file.name) {
                            this.saveMovieCover(this.movie.id)
                        } else {
                            this.$emit('save-movie', response.data.id)
                            this.$parent.toggleModal();
                            this.$parent.getMovies();
                        }
                    }).catch((error) => {console.log(error)});
                } else {
                    axios.post("/movies/", this.movie).then((response) => {
                        if (this.file.name) {
                            this.saveMovieCover(response.data.id)
                        } else {
                            this.$emit('save-movie', response.data.id)
                            this.$parent.toggleModal();
                            this.$parent.getMovies();
                        }
                    }).catch((error) => {
                        console.log(error)
                        this.messageBox.isVisible = true
                        this.messageBox.message = 'error'
                    });
                }
            },
            saveMovieCover(id) {
                let data = new FormData()
                data.append('cover', this.file, this.file.name)

                axios.patch("/movies/" + id + "/", data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    this.$emit('save-movie', response.data.id)
                    this.$parent.toggleModal();
                    this.$parent.getMovies();
                }).catch((error) => {console.log(error)});
            },
            saveAll() {
                if (this.country.name) { 
                    this.movie.country = this.getObjectFromArrayByName(this.country.name, this.countries) 
                }
                if (!this.directorName) {
                    this.saveMovie();
                } else if (this.getObjNamesFromArray(this.directors).includes(this.directorName)) {
                    this.movie.director = this.getObjectFromArrayByName(this.directorName, this.directors);
                    this.saveMovie();
                } else {
                    axios.post("/directors/", { name: this.directorName }).then((response) => {
                        this.movie.director = response.data;
                        this.saveMovie();
                        this.$parent.getDirectors();
                    }).catch((error) => {console.log(error)});
                }
            },
            confirmDeletion() {
                document.querySelector('.modal-card-body').classList.toggle('pointer-events-none');
                this.messageBox.isConfirmDelete = true
                this.messageBox.message = 'delete'
                this.messageBox.isVisible = true
            },
            abortDeletion() {
                document.querySelector('.modal-card-body').classList.toggle('pointer-events-none');
                this.messageBox.isConfirmDelete = false
                this.messageBox.message = ''
                this.messageBox.isVisible = false
            },
            deleteMovie() {
                axios.delete("/movies/" + this.movie.id + '/').then((response) => {
                    this.$parent.toggleModal();
                    this.$parent.getMovies();
                }).catch((error) => {console.log(error)});
            },
            getObjectFromArrayByName(name, objectArray) {
                return objectArray.find(element => element['name'] === name)
            },
            getImdbMovies() {
                this.messageBox.message = ''
                this.messageBox.isVisible = false;
                this.isLoading = true;
                axios.get("/imdb-movies/?search=" + this.searchTerm).then((response) => {
                    if (response.data) {
                        this.movieIndex = 0;
                        this.imdbMovies = response.data;
                        this.selectImdbMovie()
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.messageBox.message = 'noresults';
                        this.messageBox.isVisible = true;
                    }
                });
            },
            prevMovie(check) {
                if (this.movieIndex > 0) {
                    if (check) {
                        return true
                    }
                    this.movieIndex -= 1;
                    this.directorIndex = 0;
                    this.selectImdbMovie();
                }
            },
            nextMovie(check) {
                if (this.movieIndex < this.imdbMovies.length -1) {
                    if (check) {
                        return true
                    }
                    this.movieIndex += 1;
                    this.directorIndex = 0;
                    this.selectImdbMovie();
                }
            },
            prevDirector(check) {
                if (this.directorIndex > 0) {
                    if (check) {
                        return true
                    }
                    this.directorIndex -= 1;
                    this.selectImdbMovie();
                }
            },
            nextDirector(check) {
                if (this.imdbMovies.length > 0 && this.directorIndex < this.imdbMovies[this.movieIndex]['director'].length -1) {
                    if (check) {
                        return true
                    }
                    this.directorIndex += 1;
                    this.selectImdbMovie();
                }
            },
            selectImdbMovie() {
                Object.keys(this.imdbMovies[this.movieIndex]).forEach(key => {
                    this.movie[key] = this.imdbMovies[this.movieIndex][key];
                })
                this.movie.director = null
                this.movie.country = null
                this.directorName = this.imdbMovies[this.movieIndex]['director'][this.directorIndex]
                if (this.imdbMovies[this.movieIndex]['country']) this.country = this.imdbMovies[this.movieIndex]['country']
            },
            toggleFileUploadFields() {
                document.querySelector('.cover-fields').classList.toggle('slide-top');
                document.querySelector('.cover-fields').classList.toggle('slide-down');
            },
            castProps(obj) {
                Object.keys(obj).forEach(key => {
                    if (!isNaN(obj[key]) && !!obj[key]) obj[key] = String(obj[key])
                    if (this.booleanFields.includes(key)) obj[key] = Boolean(obj[key])
                })
                return obj
            }
        }
    }
</script>

<style scoped lang="scss">
.arrow-icon {
    margin-top: 5px;
    font-size: 2rem;
}
.is-full-width {
    width: 100%;
}

.tags {
    margin-top: 7px;
}

.is-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.cover-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.edit-icon {
    font-size: 1.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 20px;
    border-radius: 9999px;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
}

@media only screen and (max-width: 767px) {
  .cover-container {
    height: 150vw;
  }

  .edit-icon {
    font-size: 10vw;
    margin: 5vw;
  }
}

.cover {
    width: 100%;
    position: absolute;
    padding-left: 12px;
    padding-right: 12px;
}

.cover-image {
    border-radius: 0.5rem;
}

.cover-fields {
    top: 50%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 100%;
    padding: 12px;
}

.file {
    justify-content: center;
}

.pointer-events-none {
    pointer-events: none;
}

.is-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    z-index: 5;
    background: rgba(255, 255, 255, 0.9);
}

.spinner {
   position: absolute;
   left: calc(50% - 30px);
   top: calc(50% - 30px);
   height:60px;
   width:60px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-3 15:14:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
            opacity: 0.1;
  }
  100% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            opacity: 1;
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
            opacity: 0.1;
  }
  100% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            opacity: 1;
  }
}

@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            opacity: 1;
  }
  100% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
            opacity: 0.1;
  }
}

@keyframes slide-down {
  0% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            opacity: 1;
  }
  100% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
            opacity: 0.1;
  }
}

.slide-top {
    display: block;
	-webkit-animation: slide-top 0.5s;
	        animation: slide-top 0.5s;
}

.slide-down {
	-webkit-animation: slide-down 0.5s;
	        animation: slide-down 0.5s;
    transition: visibility 0.5s;
    visibility: hidden;
}

</style>