<template>
  <div class="home">
    <h1 class="title has-text-centered">Lists</h1>
    <div class="home-wrapper">
      <router-link to="/movies" class="movies">
        <home-card>
          <span>Filme</span>
        </home-card>
      </router-link>
      <router-link to="/recipes" class="recipes">
        <home-card>
          <span>Rezepte</span>
        </home-card>
      </router-link>
      <router-link to="/games" class="games">
        <home-card>
          <span>Videospiele</span>
        </home-card>
      </router-link>
      <router-link to="/activities" class="activities">
        <home-card>
          <span>Aktivitäten</span>
        </home-card>
      </router-link>
    </div>

  </div>
</template>

<script>
import HomeCard from '@/components/HomeCard.vue'

export default {
  name: 'Home',
  components: {
    HomeCard,
  },
}

</script>
<style scoped>

  .movies {
    grid-area: movies;
  }

  .recipes {
    grid-area: recipes;
  }

  .games {
    grid-area: games;
  }

  .activities {
    grid-area: activities;
  }

  .home-wrapper {
    display: grid;
    grid-template-rows: auto;
    margin: 20px;
    margin-top: 20px;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: 20% 20%;
    justify-content:center;
    grid-template-areas:
      "movies recipes"
      "games activities"

  }
</style>