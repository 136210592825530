<template>
    <section class="hero is-primary is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                        <form @submit.prevent="submitForm" class="box">
                            <div class="field">
                                <label for="" class="label">Username</label>
                                <div class="control has-icons-left">
                                    <input type="text" name="username" v-model="username" placeholder="z.B. Name" class="input" required>
                                    <span class="icon is-small is-left">
                                        <i class="fa fa-envelope"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="field">
                                <label for="" class="label">Passwort</label>
                                <div class="control has-icons-left">
                                    <input type="password" name="password" v-model="password" placeholder="*******" class="input" required>
                                    <span class="icon is-small is-left">
                                        <i class="fa fa-lock"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="field">
                                <label for="" class="checkbox">
                                    <input type="checkbox">
                                Remember me
                                </label>
                            </div>
                            <div class="field">
                                <button class="button is-success">
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from '@/main.js'

export default {
    name: 'LogIn',
    data() {
        return {
            username: '',
            password: '',
        }
    },
    methods: {
        submitForm(e) {
            const formData = {
                username: this.username,
                password: this.password
            }

            delete axios.defaults.headers.common["Authorization"];
            axios.post('/api/v1/token/login', formData).then(
                response => {
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)
                    localStorage.setItem("token", token)
                    window.location.href = '/';
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}

</script>

<style scoped>
    .log-in {
        margin-top: 60px;
    }
</style>