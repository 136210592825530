<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head has-background-danger has-text-white">
            <p>title</p>
        </header>
        <section class="modal-card-body has-background-danger-light has-text-danger">
            <p>sfgfgedger</p>
        </section>
        <footer class="modal-card-foot">
            <p>footer</p>
        </footer>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Confirmation'
  }
</script>

<style lang="scss" scoped>
  .modal-card {
    max-width: 500px;
  }
</style>