import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, far, fab)

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://lists.repenn.de/api'
});

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(store)
  .use(router, axios)
  .mount('#app')

instance.defaults.headers.common['Authorization'] = 'Token ' + store.state.token

export default instance;