<template>
  <nav
    class="navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a class="navbar-item" href="https://bulma.io">
        <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28">
      </a>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        v-on:click="toggleBurger()"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <div class="navbar-item">
          <div class="mr-6">
            <router-link class="button" :to="{ name: 'Movies'}">
              <strong>Filme</strong>
            </router-link>
            <router-link class="button" :to="{ name: 'Recipes'}">
              <strong>Rezepte</strong>
            </router-link>
            <router-link class="button" :to="{ name: 'Games'}">
              <strong>Videospiele</strong>
            </router-link>
            <router-link class="button" :to="{ name: 'Activities'}">
              <strong>Aktivitäten</strong>
            </router-link>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <form @submit.prevent="logOut">
              <button type="submit" class="button">Log out</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "@/main.js"

export default {

  name: "NavBar",
  computed: {
    search: {
      get () {
        return this.$store.state.search
      },
      set (value) {
        this.$store.commit('setSearch', value)
      }
    },
    genres: {
      get () {
        return this.$store.state.genres
      },
      set (value) {
        this.$store.commit('setGenres', value)
      }
    }
  },
  methods: {
    logOut() {
      axios.post("/api/v1/token/logout").then((response) => {
        localStorage.setItem("user", "");
        localStorage.setItem("token", "");
        this.$store.commit("removeToken");
        this.$router.push("log-in");
      });
    },
    toggleBurger() {
      document.querySelector('.navbar-menu').classList.toggle('is-active');
      document.querySelector('.navbar-burger').classList.toggle('is-active');
    }
  },
};
</script>
<style scoped lang="scss">

.navbar {
  background-color: grey;
}

.button {
  color: #363636;
  background-color: silver;
  border-color: #363636;
  margin: 0 8px 8px 0;
}

.button:hover {
  border-color: silver;
}

.router-link-active {
  color: silver;
  background-color: #363636;
}

.navbar-menu {
  background-color: grey;
}

.input-wrapper {
  margin: 0 8px 8px 0;
}

.has-dropdown {
  margin-bottom: 8px;
}

.navbar-item {
  &.has-dropdown {
    .navbar-dropdown {
      display: none;
    }
    &.is-active {
      .navbar-dropdown {
        display: block;
      }
    }
  }
}

.navbar-link {
  background-color: silver;
  border-style: solid;
  border-width: 1px;
  border-color: #363636;
}

.navbar-link:hover {
  color: #363636;
  border-color: white;
}
</style>
