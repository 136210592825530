<template>
    <div>
        <div 
            class="field is-horizontal"
            v-if="inputType=='text'"
        >
            <div v-if="label" class="field-label is-normal">
                <label class="label">{{ label }}</label>
            </div>
            <div class="field-body">
                <div class="field">
                    <p class="control">
                        <input
                            :value="value"
                            class="input"
                            :list="placeholder"
                            type="text"
                            :placeholder=placeholder
                            v-on:input="$emit('get-value', $event.target.value)"
                        >
                        <datalist :id="placeholder">
                            <option 
                                v-for="option in options"
                                :key="option"
                            >{{ option }}</option>
                        </datalist>
                    </p>
                </div>
            </div>
        </div>

        <div 
            class="field is-horizontal"
            v-if="inputType=='dropdown'"
        >
            <div v-if="label" class="field-label is-normal">
                <label class="label">{{ label }}</label>
            </div>
            <div class="field-body">
                <div class="select is-full-width">
                    <select
                        class="is-full-width"
                        v-on:input="$emit('get-value', $event.target.value)"
                    >
                        <option>{{ placeholder }}</option>
                        <option 
                            v-for="option in this.options"
                            :key="option"
                        >{{ option }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div 
            class="field is-horizontal"
            v-if="inputType=='multidropdown'"
        >
            <div v-if="label" class="field-label is-normal">
                <label class="label">{{ label }}</label>
            </div>
            <div class="field-body">
                <div
                    class="select is-multiple is-full-width"
                >
                    <select class="is-full-width" multiple size="4">
                        <option 
                            v-for="option in this.options"
                            :key="option"
                        >{{ option }}</option>
                    </select>
                </div>
            </div>
        </div>

        <label
            class="checkbox"
            v-if="inputType=='checkbox'"
        >
            <input type="checkbox">
            {{ label }}
        </label>

    </div>




</template>

<script>
export default {
    name: 'InputField',
    props: {
        inputType: String,
        label: String,
        placeholder: String,
        options: Array,
        value: String,
    },
}
</script>

<style>
input::-webkit-calendar-picker-indicator {
    display: none !important;
}

.is-full-width {
    width: 100%;
}
</style>