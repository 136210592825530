<template>
  <div class="card">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCard'
}
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 1fr;
  display: flex;
  flex-direction: column;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 20px 16px;
  
}

img {
  border-radius: 5px 5px 0 0;
}
</style>