<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  beforeCreate() {
    this.$store.commit("initializeStore");

    const token = this.$store.state.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }

    if (!this.$store.state.isAuthenticated) {
      this.$router.push({ name: "LogIn" });
    }
  },
};
</script>

<style lang="scss">
@import "./styles.scss";

html,
body {
  background-color: gray;
}
</style>
