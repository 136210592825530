<template>
  <div class="mt-4">
    <h1 class="title has-text-centered">Rezepte</h1>
    <div class="has-text-centered mt-6">
      <font-awesome-icon
        class="add-recipe"
        :icon="['fas', 'circle-plus']"
        v-on:click="addNewRecipe()"
      />
    </div>
    <div class="recipe-margin container">
      <div
        v-for="recipe in recipes"
        class="card"
        :key="recipe.id"
        :model="recipe"
      >
        <div class="card-content">
          <p class="title is-5 mb-2">
            {{ recipe.name }}
            <font-awesome-icon
              v-if="recipe.is_bakery_product"
              :icon="['fas', 'cookie-bite']"
            />
          </p>
          <div class="mb-1">
            <span>{{ recipe.type_of_meal }}</span>
            <font-awesome-icon class="ml-3" :icon="['fas', flavorIcons[recipe.flavor]]"/>
            <font-awesome-icon class="ml-3" :icon="['far', 'clock']"/>
            <span class="ml-1">{{ recipe.preparation_time }}</span>
            <span class="ml-1">Min.</span>
          </div>
          <div class="mb-2">
            <img
              class="image-meal"
              :src="require('@/assets/food-placeholder.png')"
              alt="Foto vom Gericht"
            >
          </div>
          <router-link
            class="font-awe-icon edit-icon"
            :to="{ name: 'EditRecipe', params: { id: recipe.id }}"
          >
            <font-awesome-icon
              :icon="['far', 'pen-to-square']"
            />
          </router-link>
          <div class="media-content">
            <font-awesome-icon
              class="expand-icon"
              :icon="recipe.isExpanded ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
              @click="recipe.isExpanded = !recipe.isExpanded"
            />
          </div>
          <div v-if="recipe.isExpanded">
            <div class="mb-2">
              <font-awesome-icon :icon="['fas', 'user']"/>
              <font-awesome-icon
                class="ml-1"
                :icon="['far', 'square-minus']"
                @click="changePortions(-1, recipe)"
              />
              <span class="ml-1">{{ recipe.portions }}</span>
              <font-awesome-icon
                class="ml-1"
                :icon="['far', 'square-plus']"
                @click="changePortions(1, recipe)"
              />
            </div>
            <table>
              <th>Menge</th>
              <th>Zutat</th>
              <tr
                v-for="ingredientAmount in recipe.ingredient_amounts"
                :key="ingredientAmount"
              >
                <td>
                  {{ ingredientAmount.amount * recipe.portions }} 
                  {{ ingredientAmount.ingredient.unit }}
                </td>
                <td>{{ ingredientAmount.ingredient.name }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="has-text-centered mt-6 mb-6">
      <font-awesome-icon
        class="add-recipe"
        :icon="['fas', 'circle-plus']"
        v-on:click="addNewRecipe()"
      />
    </div>
  </div>
</template>

<script>

import axios from "@/main.js";

export default {
  name: 'Recipes',
  data() {
    return {
      ingredients: [],
      ingredientAmounts: [],
      recipes: [],
      flavorIcons: {
        'Herzhaft': 'pizza-slice',
        'Süß': 'candy-cane',
        'Scharf': 'pepper-hot'
      }
    }
  },
  mounted() {
    this.getIngredients()
    this.getRecipes()
  },
  methods: {
    getIngredients() {
      axios.get("/ingredients/").then((response) => {
        this.ingredients = response.data;
      });
    },
    getRecipes() {
      axios.get("/recipes/").then((response) => {
        this.recipes = response.data.map(obj => (
          { ...obj, isExpanded: false, portions: 2 }
        ))
      });
    },
    changePortions(value, recipe) {
      var newValue = recipe.portions + value
      if (newValue >= 1 && newValue <= 9) {
        recipe.portions = newValue
      }
    },
    editRecipe(recipe=false) {
      console.log(recipe)
    },
    addNewRecipe() {
      this.$router.push({ path: 'edit-recipe' })
    }
  }
}

</script>
<style scoped>

.card {
  background-color: silver;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.recipe-margin {
  margin: 3rem 0.5rem 0.5rem 0.5rem;
}

.font-awe-icon {
  font-size: 0.4rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.edit-icon {
  font-size: 1.5rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  color: #363636;
}

@media only screen and (min-width: 1024px) {
  .recipe-margin {
    margin-left: 25%;
    margin-right: 25%;
  }
}

.image-meal {
  border-radius: 0.25rem;
  border: 3px solid #363636;
}

.media-content {
  max-height: 10rem;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 6;
           line-clamp: 6;
   -webkit-box-orient: vertical;
}

.expand-icon {
  font-size: 1.2rem;
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
}

.add-recipe {
  cursor: pointer;
  font-size: 5rem;
}

th, td {
  padding-right: 2rem;
  font-size: small;
}

</style>