<template>
  <nav class="pagination is-centered" role="navigation" aria-label="pagination">
    <ul class="pagination-list">
      <li v-if="pagination.hasPrev">
        <a
          class="pagination-previous pagination-link"
          v-on:click="$emit('go-to', pagination.currentPage - 1)"
        >
          Zurück
        </a>
      </li>
      <li v-else>
        <a
          class="pagination-previous pagination-link is-disabled"
        >
          Zurück
        </a>
      </li>
      <li
        v-if="pagination.currentPage > 2" v-on:click="$emit('go-to', 1)"
      >
        <a class="pagination-link">1</a>
      </li>
      <li v-if="pagination.currentPage > 3">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li>
        <a
          class="pagination-link"
          v-if="pagination.hasPrev"
          v-on:click="$emit('go-to', pagination.currentPage - 1)"
        >
          {{ pagination.currentPage - 1}}
        </a>
      </li>
      <li>
        <a class="pagination-link is-current">{{ pagination.currentPage }}</a>
      </li>
      <li>
        <a
          class="pagination-link"
          v-if="pagination.hasNext"
          v-on:click="$emit('go-to', pagination.currentPage + 1)"
        >
          {{ pagination.currentPage + 1}}
        </a>
      </li>
      <li v-if="pagination.siteCount - pagination.currentPage > 2">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li
        v-if="pagination.siteCount - pagination.currentPage > 1"
        v-on:click="$emit('go-to', pagination.siteCount)"
      >
        <a class="pagination-link">{{ pagination.siteCount }}</a>
      </li>
      <li v-if="pagination.hasNext">
        <a
          class="pagination-next pagination-link"
          v-on:click="$emit('go-to', pagination.currentPage + 1)"
        >
          Nächste
        </a>
      </li>
      <li v-else>
        <a class="pagination-next pagination-link is-disabled">Nächste</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    pagination: {
      type: Object
    }
  }
};
</script>

<style scoped>
  .pagination {
    margin-top: 2rem;
  }

  .pagination-link {
    color: #363636;
    background-color: silver;
    border-color: #363636;
    margin: 0 8px 8px 0;
  }

  .pagination-link.is-current {
    color: silver;
    background-color: #363636;
    border-color: #363636;
  }

  .pagination-link:hover {
    border-color: silver;
  }

  .is-disabled:hover {
    border-color: #363636;
  }
</style>