<template>
  <nav  id="side-menu" class="side-menu">
    <button
      id="side-button"
      class="button is-rounded is-hidden-desktop side-button"
    >
      <a
        onclick=
          "
          document.getElementById('side-menu').style.width = '75%';
          document.getElementById('side-button').style.display = 'none';
          "
      >
        <font-awesome-icon
          class="filter-icon"
          :icon="['fas', 'magnifying-glass']"
        />
      </a>
    </button>
    <ul class="menu-list is-hidden-desktop hide-button">
      <li>
        <a
          onclick=
            "
            document.getElementById('side-menu').style.width = '0';
            document.getElementById('side-button').style.display = 'flex';
            "
        >
          <font-awesome-icon
            class="filter-icon"
            :icon="['fas', 'xmark']"
          />
        </a>
      </li>
    </ul>
    <p class="menu-label">
      Aktionen
    </p>
    <ul class="menu-list">
      <li>
        <a
          v-on:click="$parent.toggleModal"
        >Film hinzufügen</a>
      </li>
    </ul>
    <p class="menu-label">
      Filter
    </p>
    <ul class="menu-list">
      <li>
        <input 
          class="input search-bar"
          type="text"
          placeholder="Suchen"
          v-model="search"
        >
      </li>
      <li class="checkbox filter">
        <label>
          <span>
            <input
              type="checkbox"
              v-model="isCurrentlyWatched"
            >
            Schauen wir gerade
          </span>
        </label>
      </li>
      <li class="checkbox filter">
        <label>
          <span>
            <input
              type="checkbox"
              v-model="isUnseen"
            >
            Nicht gesehen
          </span>
        </label>
      </li>
      <li class="checkbox filter">
        <label>
          <span>
            <input
              type="checkbox"
              v-model="isMovie"
            >
            Filme
          </span>
        </label>
      </li>
      <li class="checkbox filter">
        <label>
          <span>
            <input
              type="checkbox"
              v-model="isSeries"
            >
            Serien
          </span>
        </label>
      </li>
      <li>
        <details>
          <summary>Genres</summary>
          <ul class="menu-list">
            <li
              v-for="genre in this.genres"
              :key="genre.id"
              :model="genre"
              class="checkbox filter"
            >
              <label>
                <span>
                  <input
                    type="checkbox"
                    v-model="genre.selected"
                  >
                  {{ genre.name }}
                </span>
              </label>
            </li>
          </ul>
        </details>
      </li>
      <hr>
      <li>
        <span class="vertically-center">IMDb über </span>
        <input 
          class="input search-bar-small"
          :class="{ 'validation': imdb.isInvalid }"
          placeholder="7.4"
          v-model="imdb.value"
        >
      </li>
      <hr>
      <li>
        <span class="vertically-center">Dauer unter </span>
        <input 
          class="input search-bar-small"
          :class="{ 'validation': duration.isInvalid }"
          placeholder="90"
          v-model="duration.value"
        >
        <span> min.</span>
      </li>
      <hr>
      <li>
        <span class="vertically-center">Erscheinungsjahr zwischen </span>
        <input 
          class="input search-bar-medium"
          :class="{ 'validation': year.isInvalidStart }"
          placeholder="1994"
          v-model="year.start"
        >
        <span class="vertically-center"> und </span>
        <input 
          class="input search-bar-medium"
          :class="{ 'validation': year.isInvalidEnd }"
          placeholder="2022"
          v-model="year.end"
        >
      </li>
      <hr>
      <li>
        <a v-on:click="$store.commit('removeAllFilters')">
          Alle Filter entfernen
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "SideMenu",
  computed: {
    search: {
      get () {
        return this.$store.state.search
      },
      set (value) {
        this.$store.commit('setSearch', value)
      }
    },
    isCurrentlyWatched: {
      get () {
        return this.$store.state.isCurrentlyWatched
      },
      set (value) {
        this.$store.commit('setIsCurrentlyWatched', value)
      }
    },
    isUnseen: {
      get () {
        return this.$store.state.isUnseen
      },
      set (value) {
        this.$store.commit('setIsUnseen', value)
      }
    },
    isMovie: {
      get () {
        return this.$store.state.isMovie
      },
      set (value) {
        this.$store.commit('setIsMovie', value)
      }
    },
    isSeries: {
      get () {
        return this.$store.state.isSeries
      },
      set (value) {
        this.$store.commit('setIsSeries', value)
      }
    },
    genres: {
      get () {
        return this.$store.state.genres
      },
      set (value) {
        this.$store.commit('setGenres', value)
      }
    },
    imdb: {
      get () {
        const imdb = this.$store.state.imdb
        if (!isNaN(Number(imdb.value)) && imdb.value >= 0 && imdb.value <= 10 || imdb.value === '') {
          imdb.isInvalid = false
        } else {
          imdb.isInvalid = true
        }
        return imdb
      }
    },
    duration: {
      get () {
        const duration = this.$store.state.duration
        if (!isNaN(Number(duration.value)) && (duration.value < 999 && duration.value >= 0)) {
          duration.isInvalid = false
        } else {
          duration.isInvalid = true
        }
        return duration
      },
      set (value) {
        this.$store.commit('setDuration', value)
      }
    },
    year: {
      get () {
        const year = this.$store.state.year
        const currentYear = new Date().getFullYear()
        if (!isNaN(year.start) && year.start >= 1900 && year.start <= currentYear || year.start === '') {
          year.isInvalidStart = false
        } else {
          year.isInvalidStart = true
        }
        if (!isNaN(year.end) && year.end >= 1900 && year.end <= currentYear || year.end === '') {
          year.isInvalidEnd = false
        } else {
          year.isInvalidEnd = true
        }
        return this.$store.state.year
      },
      set (value) {
        this.$store.commit('setYear', value)
      } 
    }
  },
};
</script>

<style scoped lang="scss">
  .hide-button {
    position: absolute;
    right: 0;
    top: 4px;
  }

  .side-menu {
    position: fixed;
    // max-height: calc(100% - 52px);
    margin-top: -2rem;
    margin-left: 0.5%;
    margin-right: 0.5%;
    width: 0;
    z-index: 1;
    background-color: rgba(128,128,128, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }

  @media only screen and (min-width: 1024px) {
    .side-menu {
      width: 14rem;
      margin-top: 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    .side-menu {
      width: 18rem;
    }
  }

  @media only screen and (min-width: 1800px) {
    .side-menu {
      width: 23rem;
    }
  }

  li {
    display: block;
  }

  .menu-label {
    color: silver;
  }

  summary {
    padding: 8px 12px 8px 12px
  }

  label {
    margin: 8px 12px 8px 12px
  }

  .filter {
    padding: 0.2rem
  }

  .search-bar {
    margin-bottom: 0.5rem;
    width: 50%;
  }

  .search-bar-small {
    width: 3.5rem;
  }

  .search-bar-medium {
    width: 4rem;
  }

  .nobr {
    white-space: nowrap;
  }

  .vertically-center {
    height: 40px;
    line-height: 40px;
  }

  .validation {
    border-color: red;
  }

  hr {
    margin: 10px 0 10px 0;
    background-color: silver;
  }

  .side-button {
    position: fixed;
    z-index: 1;
    right: 3rem;
    bottom: 2rem;
    background-color: #363636;
    border-color: #363636;
    padding: 10px;
    opacity: 0.6;
  }

  .filter-icon {
    padding: 0 1px 0 1px;
    color: #f2f2f2;
  }

</style>