<template>
  <div class="activities">
    <h1 class="title has-text-centered">Aktivitäten</h1>
  </div>
</template>

<script>

export default {
  name: 'Activities',
  components: {
  },
}

</script>
<style scoped>

</style>