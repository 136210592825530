<template>
  <article id="box" class="message is-danger">
    <div class="message-body">
      {{ this[message] }}
    </div>
  </article>
</template>

<script>
  export default {
    name: 'MessageBox',
    data() {
      return {
        noresults: 'Keine Ergebnisse gefunden',
        delete: 'Film wirklich unwiderruflich löschen?',
        error: 'Das hat leider nicht geklappt :('
      }
    },
    mounted() {
      if (this.message === 'delete') document.getElementById('box').scrollIntoView({ left: 0, bottom: 0, behavior: 'smooth' });
    },
    props: {
      message: String
    }
  }
</script>