import { createStore } from 'vuex'

export default createStore({
    state: {
        token: '',
        isAuthenticated: false,
        search: '',
        isCurrentlyWatched: false,
        isUnseen: false,
        isMovie: false,
        isSeries: false,
        genres: [],
        tags: [],
        countries: [],
        emojis: [],
        directors: [],
        imdb: { value: '', isInvalid: false },
        duration: { value: '', isInvalid: false },
        year: { start: '', end: '', isInvalidStart: false, isInvalidEnd: false }
    },
    mutations: {
        initializeStore(state) {
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token')
                state.isAuthenticated = true
            } else {
                state.token = ''
                state.isAuthenticated = false
            }
        },
        setToken(state, token) {
            state.token = token
            state.isAuthenticated = true
        },
        removeToken(state) {
            state.token = ''
            state.isAuthenticated = false
        },
        setSearch(state, search) {
            state.search = search
        },
        removeSearch(state) {
            state.search = ''
        },
        setIsCurrentlyWatched(state, isCurrentlyWatched) {
            state.isCurrentlyWatched = isCurrentlyWatched
        },
        setIsUnseen(state, isUnseen) {
            state.isUnseen = isUnseen
        },
        setIsMovie(state, isMovie) {
            state.isMovie = isMovie
            if (isMovie) {
                state.isSeries = false
            }
        },
        setIsSeries(state, isSeries) {
            state.isSeries = isSeries
            if (isSeries) {
                state.isMovie = false
            }
        },
        setGenres(state, genres) {
            state.genres = genres
        },
        setTags(state, tags) {
            state.tags = tags
        },
        setCountries(state, countries) {
            state.countries = countries
        },
        setEmojis(state, emojis) {
            state.emojis = emojis
        },
        setDirectors(state, directors) {
            state.directors = directors
        },
        setImdb(state, imdb) {
            state.imdb = imdb
        },
        setDuration(state, duration) {
            state.duration = duration
        },
        setYear(state, year) {
            state.year = year
        },
        removeSelectedGenres(state) {
            state.genres.forEach((genre, index) => {
                if (state.genres[index].selected) {
                    state.genres[index].selected = false
                }
            })
        },
        removeAllFilters(state) {
            state.search = ''
            state.isMovie = false
            state.isSeries = false
            state.isUnseen = false
            state.isCurrentlyWatched = false
            state.imdb = { value: '', isInvalid: false }
            state.duration = { value: '', isInvalid: false }
            state.year = { start: '', end: '', isInvalidStart: false, isInvalidEnd: false }
            state.genres.forEach((genre, index) => {
                if (state.genres[index].selected) {
                    state.genres[index].selected = false
                }
            })
        }
    },

    actions: {

    },
    modules: {

    }
})