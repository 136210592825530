<template>
  <div class="games">
    <h1 class="title has-text-centered">Videospiele</h1>
  </div>
</template>

<script>

export default {
  name: 'Games',
  components: {
  },
}

</script>
<style scoped>

</style>