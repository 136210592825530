<template>
  <div class="my-4">
    <div v-if="this.recipe.id">
      <h1 class="title has-text-centered">
        Rezept bearbeiten
      </h1>
    </div>
    <div v-else>
      <h1 class="title mt-3 has-text-centered">
        Neues Rezept
      </h1>
    </div>
    <div class="mt-3 mx-4">
      <div class="mt-2">
        <b>Name</b>
        <input-field
          :value="recipe.name"
          inputType="text"
          placeholder="Rezept Name"
          @get-value="(value) => recipe.name = value"
        ></input-field>
        <!-- <p class="is-size-7 has-text-danger">sdfgsg</p> -->
      </div>
      <div class="mt-2">
        <b>Zubereitungszeit in Minuten</b>
        <input-field
          :value="recipe.preparation_time.toString()"
          inputType="text"
          @get-value="(value) => recipe.preparation_time = value"
        ></input-field>
      </div>
  
      <div class="mt-2">
        <b>Art des Gerichts</b>
        <input-field
          inputType="dropdown"
          :placeholder="recipe.type_of_meal || ''"
          :options="mealTypes"
          @get-value="(value) => recipe.type_of_meal = value"
        ></input-field>
      </div>

      <div class="mt-2">
        <b>Geschmacksrichtung</b>
        <input-field
          inputType="dropdown"
          :placeholder="recipe.flavor || ''"
          :options="flavors"
          @get-value="(value) => recipe.flavor = value"
        ></input-field>
      </div>

      <div class="mt-2">
        <p>
          <b>Backwaren</b>
        </p>
        <button
          class="button"
          :class="{'is-selected is-success': recipe.is_bakery_product }"
          v-on:click="recipe.is_bakery_product=!recipe.is_bakery_product"
        >
          <font-awesome-icon
              class="is-size-5"
              :icon="['fas', 'cookie-bite']"
          />
        </button>
      </div>

      <div class="mt-2">
        <p>
          <b>Zutaten</b>
        </p>
        <div class="ingredients p-4 card">
          <div
            v-for="ingredientAmount in recipe.ingredient_amounts"
            :key="ingredientAmount.id"
            class="columns is-mobile is-vcentered"
          >
            <input-field
              :value="ingredientAmount.amount.toString() || ''"
              class="column is-3"
              inputType="text"
              placeholder="0"
              @get-value="(value) => ingredientAmount.amount = value.toString()"
            ></input-field>
            <p class="column is-1">
              {{ ingredientAmount.ingredient.unit }}
            </p>
            <input-field
              class="column is-7"
              inputType="dropdown"
              :placeholder="ingredientAmount.ingredient.name || ''"
              :options="ingredients.map(ingredient => ingredient.name)"
              @get-value="(value) => ingredientAmount.ingredient = getIngredientFromName(value)"
            ></input-field>
            <div class="column is-1">
              <button class="minus-button is-size-6" v-on:click="removeIngredient(ingredientAmount)">
                <font-awesome-icon
                  :icon="['fas', 'minus']"
                />
              </button>
            </div>
          </div>
          <button 
            class="button is-fullwidth is-flex is-justify-content-center"
            v-on:click="addIngredient()"
          >
            <font-awesome-icon
              class="is-size-4"
              :icon="['fas', 'plus']"
            />
          </button>
        </div>
      </div>
      <div class="mt-4 is-flex is-justify-content-end gap">
        <button
          class="button is-success"
          v-on:click="saveRecipe()"
          >
          Speichern
        </button>
        <button
          class="button is-secondary"
          v-on:click="$router.push({ path: '/recipes' })"
        >
          Abbrechen
        </button>
        <button
          class="button is-danger"
          v-on:click="deleteRecipe()"
        >
          Löschen
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/main.js";
import InputField from "@/components/InputField";

export default {
  name: 'EditRecipe',
  data() {
    return {
      // validators: {
      //   name: {
      //     'isValid': true,
      //   }
      // },

      name: '',
      ingredientAmounts: [],
      ingredients: [],
      ingredientToAdd: [],
      mealTypes: [
        'Hauptspeise',
        'Beilage',
        'Nachtisch',
        'Frühstück'
      ],
      flavors: [
        'Süß',
        'Herzhaft',
        'Scharf'
      ],
      recipe: {
        id: null,
        name: null,
        ingredient_amounts: [
        {
          amount: 0,
          recipe: null,
          ingredient: {
            category: null,
            id: null,
            name: '',
            unit: null,
          },
        }
        ],
        is_bakery_product: false,
        preparation_time: 0,
        type_of_meal: null,
        flavor: null
      }
    }
  },
  mounted() {
    this.getRecipe()
    this.getIngredientAmounts()
    this.getIngredients()
  },
  methods: {
    getRecipe() {
      if (this.$route.params.id) {
        axios.get("/recipes/" + this.$route.params.id + '/').then((response) => {
          this.recipe = response.data
          this.name = response.data.name
        }).catch((error) => {console.log(error)});
      }
    },
    getIngredientAmounts() {
      axios.get("/ingredient-amounts/").then((response) => {
        this.ingredientAmounts = response.data
      }).catch((error) => {console.log(error)});
    },
    getIngredients() {
      axios.get("/ingredients/").then((response) => {
        this.ingredients = response.data
      }).catch((error) => {console.log(error)});
    },
    addIngredient() {
      this.recipe.ingredient_amounts.push(
        { 'amount': '', 'ingredient': { 'name': '' }, 'recipe': this.recipe.id }
      )
    },
    removeIngredient(ingredientAmount) {
      var index = this.recipe.ingredient_amounts.indexOf(ingredientAmount)
      this.recipe.ingredient_amounts.splice(index, 1)
    },
    saveIngredientAmount(ingredientAmount) {
      axios.post("/ingredient-amounts/", ingredientAmount).then((response) => {
        }).catch((error) => {console.log(error)});
    },
    saveRecipe() {
      if (this.recipe.preparation_time === '') {
        this.recipe.preparation_time = 0
      }
      var newAmounts = this.recipe.ingredient_amounts.filter(amount => amount.recipe === null)
      if (this.recipe.id) {
        axios.put("/recipes/" + this.recipe.id + '/', this.recipe).then((response) => {
          this.$router.push({ path: '/recipes' })
        }).catch((error) => {console.log(error)});
      } else {
        this.recipe.ingredient_amounts = []
        axios.post("/recipes/", this.recipe).then((response) => {
          this.recipe = response.data
          newAmounts.forEach(element => {
            element.recipe = this.recipe.id
            this.saveIngredientAmount(element)
          });
          this.$router.push({ path: '/recipes' })
        }).catch((error) => {console.log(error)});
      }
    },
    deleteRecipe() {
      if (this.recipe.id) {
        axios.delete("/recipes/" + this.recipe.id + '/').then((response) => {
        }).catch((error) => {console.log(error)});
      }
      this.$router.push({ path: '/recipes' })
    },
    getIngredientFromName(name) {
      return this.ingredients.find(element => element['name'] === name)
    },
  },
  components: {
    InputField
  }
}

</script>

<style scoped>
.minus-button {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 4px;
  border-color: transparent;
  background-color: hsl(348, 100%, 61%);
  color: white;
  cursor: pointer;
}
.gap {
  gap: 0.5rem;
}
.ingredients {
  background-color: silver;
}
</style>